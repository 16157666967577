import React from 'react'

import { IntroductionSection } from "./IntroductionSection";
import { ProjectSection } from "./ProjectSection";


export const LandingPage = () => {
    return (
        <div>
            <IntroductionSection></IntroductionSection>
            <ProjectSection></ProjectSection>
        </div>
    )
}
